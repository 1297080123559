<template>
  <div class="row" ref="sessionForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed, errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="name"
                                name="name"
                                fou
                                @keyup="generateSomeFields"
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed, errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="URL"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="title"
                      rules=""
                      name="The Title"
                      v-slot="{ passed, failed, errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Title"
                                name="title"
                                fou
                                v-model="formData.title">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <ValidationProvider
                  vid="forum"
                  rules="required"
                  name="The Edition"
                  v-slot="{ passed, failed, errors }">
                  <fg-select
                    name="forum_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Edition"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Edition'"
                    @change="changeByForum"
                    :list="builderData.forumList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.forum_id">
                  </fg-select>
                </ValidationProvider>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="speakers"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Speakers"
                      :input-classes="'select-default'"
                      :label="'Speakers'"
                      :list="builderData.speakersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.speakers">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <fg-select
                      name="master_of_the_day"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Masters of The Day"
                      :input-classes="'select-default'"
                      :label="'Masters of The Day'"
                      :list="builderData.speakersList" 
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.master_of_the_day"> 
                    </fg-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="facilitators"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Facilitators"
                      :input-classes="'select-default'"
                      :label="'Facilitators'"
                      :list="builderData.speakersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.facilitators">
                    </fg-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="moderators"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Moderators"
                      :input-classes="'select-default'"
                      :label="'Moderators'"
                      :list="builderData.speakersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.moderators">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <fg-select
                      name="panelists"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Panelists"
                      :input-classes="'select-default'"
                      :label="'Panelists'"
                      :list="builderData.speakersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.panelists">
                    </fg-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="day"
                      size="large"
                      filterable
                      clearable
                      placeholder="Select Day"
                      :input-classes="'select-default'"
                      :label="'Days'"
                      :list="builderData.daysList"
                      :listItemLabel="'date'"
                      :listItemValue="'id'"
                      v-model="formData.day_id">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <fg-select
                      name="topics"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Select Topics"
                      :input-classes="'select-default'"
                      :label="'Topics'"
                      :list="builderData.topicsList"
                      :listItemLabel="'title'"
                      :listItemValue="'id'"
                      v-model="formData.topics">
                    </fg-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="card-label">From Time</label>
                    <ValidationProvider
                      vid="from_time"
                      rules="required"
                      name="The From Time"
                      v-slot="{ passed, failed, errors }">
                      <fg-input v-model="formData.from_time"
                                :error="failed ? errors[0]: null"
                                value-format="HH:MM:SS"
                                type="time"
                                placeholder="From Time">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <label class="card-label">To Time</label>
                    <ValidationProvider
                      vid="to_time"
                      rules="required"
                      name="The To Time"
                      v-slot="{ passed, failed, errors }">
                      <fg-input v-model="formData.to_time"
                                value-format="HH:MM:SS"
                                :error="failed ? errors[0]: null"
                                type="time"
                                placeholder="To Time">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group pt-4">
                  <label>Is Featured</label>&nbsp;
                  <l-switch v-model="formData.is_featured">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/sessions/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { Option, Select, Tooltip } from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, { ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch } from "@/components";
import Editor from '@tinymce/tinymce-vue';

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: '',
        title: "",
        url: "",
        from_time: '',
        to_time: '',
        speakers: [],
        master_of_the_day: [],
        content: "",
        is_active: true,
        is_featured: false,
        forum_id: '',
        topics: [],
        day_id: '',
        moderators: [],
        panelists: [],
        facilitators: [],
      },
      builderData: {
        speakersList: [],
        daysList: [],
        topicsList: [],
        forumList: []
      },
      currentEditedFormData: {
        speakers: [],
        moderators: [],
        master_of_the_day: [],
        panelists: [],
        facilitators: [],
        forum_id: '',
        day_id: '',
      }
    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.sessionForm
    });

    setTimeout(() => {
      if (this.loader) {
        this.loader.hide();
      }
    }, 10000);

    let data = {}
    this.axios.post("sessions/builder", data).then((response) => {
      this.builderData.forumList = response.data.forums;
      this.builderData.topicsList = response.data.topics;
      this.formData.forum_id = response.data.currentForum ? response.data.currentForum.id : null;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Session";
        this.getSession();
      } else {
        this.editMode = false;
        this.formTitle = "Add Session";
        this.changeByForum().finally(() => {
          this.loader.hide();
        });
      }
    }).catch((error) => {
      console.error(error);
      this.$notify({
        message: "Error loading form data",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    });
  },
  methods: {
    getSession() {
      let data = {
        'id': this.id,
        '_timestamp': new Date().getTime()
      };
      return this.axios.post("sessions/get", data).then(async (response) => {
        let builderData = {
          forum_id: response.data.forum_id
        };
        await this.axios.post("sessions/builder", builderData).then((builderResponse) => {
          this.builderData.speakersList = builderResponse.data.speakers;
          this.builderData.daysList = builderResponse.data.days;
        });

        console.log('Session response:', response.data);
        console.log('Masters before mapping:', response.data.master_of_the_day);

        this.formData = {
          ...response.data,
          topics: response.data.topics?.map(topic => topic.id) || [],
          speakers: response.data.speakers?.map(speaker => speaker.id) || [],
          moderators: response.data.moderators?.map(moderator => moderator.id) || [],
          panelists: response.data.panelists?.map(panelist => panelist.id) || [],
          facilitators: response.data.facilitators?.map(facilitator => facilitator.id) || [],
          master_of_the_day: response.data.masters?.map(master => master.id) || []
        };

        console.log('Masters after mapping:', this.formData.master_of_the_day);

        this.currentEditedFormData = {
          forum_id: this.formData.forum_id,
          master_of_the_day: [...(this.formData.master_of_the_day || [])],
          day_id: this.formData.day_id,
          speakers: [...(this.formData.speakers || [])],
          moderators: [...(this.formData.moderators || [])],
          panelists: [...(this.formData.panelists || [])],
          facilitators: [...(this.formData.facilitators || [])]
        };

      }).catch((error) => {
        if (error.response?.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Session Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      }).finally(() => {
        this.loader.hide();
      });
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      const submitData = {
        id: this.id,
        name: this.formData.name,
        title: this.formData.title,
        url: this.formData.url,
        from_time: this.formData.from_time,
        to_time: this.formData.to_time,
        content: this.formData.content,
        is_active: this.formData.is_active,
        is_featured: this.formData.is_featured,
        forum_id: this.formData.forum_id,
        day_id: this.formData.day_id,
        speakers: Array.isArray(this.formData.speakers) ? this.formData.speakers : [],
        moderators: Array.isArray(this.formData.moderators) ? this.formData.moderators : [],
        panelists: Array.isArray(this.formData.panelists) ? this.formData.panelists : [],
        facilitators: Array.isArray(this.formData.facilitators) ? this.formData.facilitators : [],
        topics: Array.isArray(this.formData.topics) ? this.formData.topics : [],
        master_of_the_day: Array.isArray(this.formData.master_of_the_day) ? this.formData.master_of_the_day : []
      };

      console.log('Submitting data:', submitData);

      if (this.editMode === true) {
        request = this.axios.put(`sessions/update/${this.id}`, submitData);
        successMessage = "Session Updated Successfully";
      } else {
        request = this.axios.post("sessions/create", submitData);
        successMessage = "Session Added Successfully";
      }

      request.then(async (response) => {
        console.log('Update response:', response);

        if (this.editMode) {
          await new Promise(resolve => setTimeout(resolve, 500));
          await this.getSession();
        }

        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        });

        setTimeout(() => {
          this.$router.push("/sessions/list");
        }, 100);
      }).catch((error) => {
        console.error('Update error:', error.response?.data || error);
        if (error.response?.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          this.$notify({
            message: error.response?.data?.message || "Error updating session",
            timeout: 2000,
            type: 'danger'
          });
        }
      }).finally(() => {
        this.submitting = false;
      });
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }
      this.formData.url = newUrl;
    },
    changeByForum() {
      if (this.editMode && this.formData.forum_id === this.currentEditedFormData.forum_id) {
        return Promise.resolve();
      }

      if (!this.editMode || this.formData.forum_id !== this.currentEditedFormData.forum_id) {
        this.formData.speakers = [];
        this.formData.moderators = [];
        this.formData.master_of_the_day = [];
        this.formData.panelists = [];
        this.formData.facilitators = [];
        this.formData.day_id = '';
      }
      
      let data = {
        forum_id: this.formData.forum_id
      };
      
      return this.axios.post("sessions/builder", data).then((response) => {
        this.builderData.speakersList = response.data.speakers;
        this.builderData.daysList = response.data.days;
      }).catch((error) => {
        console.error(error);
        this.$notify({
          message: "Error loading forum data",
          timeout: 2000,
          type: 'danger'
        });
      });
    }
  }
}
</script>